@import "~font-awesome";

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: "Open Sans", sans-serif;
  position: relative;
  padding-bottom: 160px;
}

header {
  background: #010101;
  border-bottom: 10px solid #BE5673;
  padding: 40px 0;
  text-align: center;
  width: 100%;
}

footer {
  background: #010101;
  border-top: 10px solid #74869E;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 30px 0;
}

footer h1 {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 32px;
  margin-bottom: 15px;
}

footer p {
  color: #999;
  font-size: 16px;
}

.login-container {
  background: #fff;
  width: 100%;
  float: right;
  padding: 100px 30px 100px 30px;
}

.login-container::before {
  content: '';
  position: absolute;
  background: url("/assets/login/rj-login.png") center center;
  -webkit-background-size: cover;
  background-size: cover;
  left: 0;
  top: 240px;
  bottom: 160px;
  width: 0;
}

.login-content {
  max-width: 600px;
  margin: 0 auto;
}

.btn-outline {
  background: #fff;
  color: #BE5673;
  border: 2px solid #BE5673;
  font-family: Lato, sans-serif;
  font-size: 28px;
  text-transform: uppercase;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  padding: 15px 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.btn-outline:hover {
  background: #BE5673;
  color: #fff;
  cursor: pointer;
}

.btn-outline .fa {
  font-size: 1.2em;
  margin-left: 10px;
}

.create-account {
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: 300;
  font-size: 24px;
  color: #414142;
  margin: 60px 0 20px 0;
}

.login-container input {
  background: #E6E7E8;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 300;
  padding: 20px;
}

.login-container input:focus {
  background: #F3F4F5;
}

.forgot-password {
  color: #6D6E71;
}

.forgot-password i, .forgot-password:hover, .forgot-password:active, .forgot-password:focus {
  color: #BE5673;
}

.forgot-password i {
  margin-right: 5px;
  font-size: 1.2em;
}

.btn-submit {
  margin-top: 30px;
}

/*
    Custom checkbox
    ------------------------------------------------------------------------
 */
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #6D6E71;
}

.control:hover {
  color: #BE5673;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #E6E7E8;
}

.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #F3F4F5;
}
.control input:checked ~ .control__indicator {
  background: #BE5673;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #7E394C;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}

@media (min-width: 992px) {
  .login-container {
    width: 70%;
  }

  .login-container::before {
    width: 30%;
  }
}

@media (min-width: 1200px) {
  .login-container {
    width: 50%;
  }

  .login-container::before {
    width: 50%;
  }
}